import { Auth } from "aws-amplify";
import * as AuthUtils from "utils/auth";
import FetchAction from "../FetchAction";
import UpdateAction from "../UpdateAction";
import * as API from "../../rest/API";
import store from "../ReduxStore";
import { setData2, setCookies, invalidateAll } from "./data.actions";
import { isFetchingUser } from "../selectors/user.selector";
import { moment, SecurityUtils } from "revlock-webutils";
import config from "cfg/config";
import queryString from "query-string";
import * as ChargebeeUtils from "../../utils/ChargebeeUtils";

const organizationsFetch = () =>
    new FetchAction("ORGANIZATIONS", API.getUserOrganizations);
const organizationUpdate = (remoteAction, reducerType) =>
    new UpdateAction("ORGANIZATIONS", remoteAction, reducerType);

const organizationsUsersFetcher = () =>
    new FetchAction("ORGANIZATIONS_USERS", API.getOrganizationUsers);
const organizationsUsersUpdater = (remoteAction, reducerType) =>
    new UpdateAction(
        "ORGANIZATIONS_USERS",
        remoteAction || API.getOrganizationUsers,
        reducerType
    );

const syncFetcher = () => new FetchAction("SYNC", API.getSyncStatus);
const syncUpdater = (remoteAction, reducerType) =>
    new UpdateAction("SYNC", remoteAction || API.getSyncStatus, reducerType);

const taskFetcher = () => new FetchAction("ALL_TASKS", API.getTaskSummary);

export default class User {
    static signIn = async () => {
        const isFetching = isFetchingUser(store.getState());

        if (!isFetching) {
            store.dispatch({
                type: "USER",
                status: "request"
            });
            //mark as start fetching.

            let user, userInfo, serverUser;
            try {
                user = await Auth.currentAuthenticatedUser({
                    bypassCache: true
                });
                serverUser = await API.getUser();
                userInfo = await Auth.currentUserInfo();
            } catch (e) {
                console.error(
                    `Failed to get current authenticated user ${e}`,
                    e.stack
                );
                //when by pass cache is used this method can throw!
            }

            if (user && userInfo && user.attributes && serverUser) {
                if (ChargebeeUtils.isChargebeeUser()) {
                    let {
                        isValid
                    } = await ChargebeeUtils.validateChargebeeUiSession();
                    if (!isValid) {
                        localStorage.clear();
                        User.signOut(true);
                        return;
                    }
                }

                // The 'user' object has a username of the underlying user
                // (even if impersonating it will be the super-admin) but their
                // superAdminLevel is from AWS which might be wrong and out of
                // date now that we store it in redis.  So get the user from
                // the server which will have the correct superAdminLevel and
                // if the user is being impersonated will have the actualAdminUser
                // attribute containing the underlying super-admin user.
                const underlyingSuperAdminLevel = serverUser.actualAdminUser
                    ? SecurityUtils.getSuperAdminLevel(
                          serverUser.actualAdminUser
                      )
                    : SecurityUtils.getSuperAdminLevel(serverUser);
                user.superAdminLevel = SecurityUtils.getSuperAdminLevel(
                    serverUser
                );

                user.lastLogon =
                    user &&
                    user.attributes &&
                    user.attributes["custom:last-logon"];

                user.id = userInfo && userInfo.id;

                if (
                    underlyingSuperAdminLevel >=
                    SecurityUtils.SUPER_ADMIN_LEVEL_1
                ) {
                    const impersonatedUsername =
                        user.attributes["custom:impersonate"];

                    try {
                        if (
                            impersonatedUsername &&
                            impersonatedUsername !== ""
                        ) {
                            const impersonatedUser = await API.getImpersonatedUser(
                                impersonatedUsername
                            );
                            if (impersonatedUser) {
                                user.attributes = impersonatedUser.attributes;
                                user.id = impersonatedUser.id;
                                user.impersonation = true;
                                user.superAdminLevel = SecurityUtils.getSuperAdminLevel(
                                    impersonatedUser
                                );
                            }
                        }
                    } catch (err) {
                        console.error(
                            `Unable to impersonate as [ ${err.toString()}] for ${impersonatedUsername} `
                        );
                    }
                }

                try {
                    user.authorizations = await API.getAuthorizations();
                } catch (err) {
                    console.log(err);
                }

                user.orgs = user.authorizations;

                if (user && user.attributes && user.attributes["custom:data"]) {
                    const data = user.attributes["custom:data"];
                    if (data && data.length > 0) user.data = JSON.parse(data);
                }

                user.idp = serverUser.idp;
                if (serverUser.idp === "chargebee-idp") {
                    user.isChargebeeUser = true;
                    user.chargebeeIdpType = serverUser.chargebeeIdpType;
                    user.logoutUrl = serverUser.logoutUrl;
                }

                store.dispatch({
                    type: "USER",
                    status: "success",
                    user
                });

                if (!user.impersonation)
                    await Auth.updateUserAttributes(user, {
                        "custom:last-logon": moment().format(
                            "MMM, DD YYYY hh:mm a"
                        )
                    });
            } else {
                localStorage.clear();

                await User.signOut();
                //sign out if you cannot get the user or user info
                store.dispatch({
                    type: "NO_USER"
                });
            }
        }
    };

    static signOut = async (value = true) => {
        if (Auth && Auth._oAuthHandler && Auth._oAuthHandler._config) {
            Auth._oAuthHandler._config.redirectSignOut =
                config.AWS.oauth.redirectSignOut;
        }

        //Hack since the oauth handler signout is not set properly automatically
        ChargebeeUtils.removeChargebeeUiSession();

        await AuthUtils.signOut({ global: value });
        sessionStorage.clear();
        store.dispatch({
            type: "SIGN_OUT"
        });
    };

    static refreshUser = async (callback) => {
        const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
        user.refreshSession(user.signInUserSession.refreshToken, () => {
            Auth.currentUserCredentials().then(() => {
                User.signIn();
                callback();
            });
        });
    };

    static initDemoOrg(org) {
        organizationUpdate(API.initDemoOrg).doUpdate(false, undefined, org);
    }

    static initProdTest(org) {
        organizationUpdate(API.initProdTest).doUpdate(false, undefined, org);
    }

    static updateOrganizationImage(params, callback, invalidate = true) {
        organizationUpdate(
            API.updateOrganizationImage,
            "updateOrgImg"
        ).doUpdate(invalidate, callback, params);
    }

    static updateOrganization(
        org,
        periodType,
        callback,
        invalidate = false,
        silent = false
    ) {
        if (org.image) delete org.image;

        const action = organizationUpdate(API.updateOrganization);
        if (!silent) action.doUpdate(invalidate, callback, org, periodType);
        else action.doUpdateSilent(invalidate, callback, org, periodType);
    }

    static setCurrentOrganization(org, logoutMstr) {
        invalidateAll();
        store.dispatch({
            type: "RESET_ORG",
            status: "success"
        });

        if (logoutMstr) {
            setData2("doMstrLogout", true);
        }

        if (window.HotGlue) {
            window.HotGlue.setTenant && window.HotGlue.setTenant(org.id);
        }

        setCookies("orgId", org.id, { path: "/" });

        store.dispatch({
            type: "CURRENT_ORGANIZATION",
            status: "success",
            response: org
        });
    }

    static inviteUserForOrganization(params, callback) {
        organizationsUsersUpdater(
            API.inviteUserForOrganization,
            "invitedUser"
        ).doUpdate(true, callback, params);
    }

    static removeUserFromOrganization(
        user,
        params,
        callback,
        invalidate = true
    ) {
        organizationsUsersUpdater(
            API.removeUserFromOrganization,
            "removeUser"
        ).doUpdate(invalidate, callback, params);

        // figureout if user opened sso for signup process
        const queryParams =
            (window.location.search &&
                queryString.parse(window.location.search)) ||
            {};
        const cbsso =
            (queryParams.cbssoredirect &&
                queryParams.cbssoredirect === "true") ||
            (window.location.origin.match(/-sso.revrec/) &&
                window.location.origin.match(/-sso.revrec/).length > 0);

        store.dispatch({
            type: "ORGANIZATIONS_USERS_REMOVE",
            data: user,
            cbsso
        });
    }

    static updateOrganizationUser(params, callback) {
        organizationsUsersUpdater(
            API.updateOrganizationUser,
            "updateUserRole"
        ).doUpdate(true, callback, params);
    }

    static fetchCurrentOrganzationUsers(state, stateFilter, ...args) {
        return organizationsUsersFetcher().doFetch(state, stateFilter, ...args);
    }

    static fetchUserOrganizations(state, stateFilter, ...args) {
        return organizationsFetch().doFetch(state, stateFilter, ...args);
    }

    static getOrganizationImage(orgs, callback) {
        return organizationUpdate(API.getOrgImages, "OrgsImages").doUpdate(
            false,
            callback,
            orgs
        );
    }

    /**
     * Task Actions
     */
    static fetchAllTasks(state, stateFilter, ...args) {
        return taskFetcher().doFetch(state, stateFilter, ...args);
    }

    /**
     * Sync Actions
     */
    static fetchSync(state, stateFilter, ...args) {
        return syncFetcher().doFetch(state, stateFilter, ...args);
    }

    static syncStatus(params, callback) {
        syncUpdater().doUpdate(false, callback, params);
    }

    static triggerSync(params, callback, invalidate = false) {
        syncUpdater(API.triggerSync, "triggerSync").doUpdate(
            invalidate,
            callback,
            params
        );
    }

    static invalidateSync() {
        store.dispatch({
            type: "INVALIDATE_SYNC"
        });
    }

    static invalidateOrganizationUsers = () => {
        store.dispatch({
            type: "ORGANIZATION_USERS_INVALIDATE"
        });
    };

    static invalidateOrganization = () => {
        store.dispatch({
            type: "ORGANIZATION_INVALIDATE"
        });
    };
}
