import { withAuthenticator } from "aws-amplify-react";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { LinearProgress } from "@material-ui/core";
import * as AuthUtils from "utils/auth";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "utils/WebUtils";
import { Auth } from "aws-amplify";
import { withCookies } from "react-cookie";
import {
    ConfirmSignIn,
    ConfirmSignUp,
    ForgotPassword,
    SignIn,
    SignUp,
    VerifyContact,
    RequireNewPassword
} from "revlock-ui-signup";

import { moment } from "revlock-webutils";
import GA from "./utils/GoogleAnalytics";
import { AccountingUtils } from "revlock-accounting";
import Routes from "./routes";
import { SecurityUtils } from "revlock-webutils";
import * as ChargebeeUtils from "./utils/ChargebeeUtils";

import {
    getAppConfig,
    sync,
    getClientTablesConfig,
    getOrgConfigurations,
    customers,
    products
} from "./redux/selectors/data.selector";

import { CookiesProvider } from "react-cookie";
import {
    currentUser,
    currentOrganization,
    userOrganizations
} from "./redux/selectors/user.selector";

import { singleton, getHGPublicApiKey } from "utils/WebUtils";

import IdleTimer from "react-idle-timer";
import swal from "sweetalert";

import * as API from "rest/API";

import MstrLogout from "containers/reports/MstrLogout";
import * as redux from "./redux";
import { setData } from "./redux/actions/data.actions";
import { toggleSidenav } from "./redux/actions/layout.actions";
import User from "./redux/actions/user.actions";
import UserActions from "redux/actions/user.actions";
import config from "cfg/config";
import themes from "./themes";
import Org from "utils/Org";
import queryString from "query-string";

const revlockTheme = themes[0].theme.contentTheme;
const TIMEOUT = 1000 * 60 * 30; // 30 minute timeout.

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.init(props, {});
        this.idleTimer = null;
        this.handleOnIdle = this.handleOnIdle.bind(this);
        this.mountHotGlue = this.mountHotGlue.bind(this);
        this.refreshChargebeeToken = this.refreshChargebeeToken.bind(this);
    }

    handleOnIdle(event) {
        const { orgConfig } = this.props;

        const isAdvancedSecurityEnabled = () => {
            const ase =
                orgConfig &&
                orgConfig.find((e) => e.id === "properties/advancedSecurity");

            return !ase ? false : ase.value;
        };

        if (orgConfig && isAdvancedSecurityEnabled()) {
            const lastActiveTime =
                this.idleTimer == null
                    ? "long time"
                    : moment(this.idleTimer.getLastActiveTime()).format(
                          "h:mm:ss a"
                      );
            AuthUtils.signOut({ global: true }).then(async () => {
                await swal(
                    "Security Warning",
                    `You have been idle since ${lastActiveTime} and we logged you out to protect your account.`,
                    "warning"
                );

                window.location = "/";
            });
        }
    }

    mountHotGlue(currentOrganization) {
        const orgId = currentOrganization.id;

        const head = document.getElementsByTagName("head")[0];
        const script = document.createElement("script");
        head.appendChild(script);
        script.type = "text/javascript";
        script.onload = function() {
            getHGPublicApiKey(orgId, config.hotGlue);
        };
        script.src = "https://hotglue.xyz/widgetv2.js";
        //load up hotglue.
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps != this.props) {
            this.setState(this.init(nextProps, this.state));
        }

        if (nextProps.clientTables && !this.props.clientTables) {
            nextProps.clientTables.forEach((clientTableCfg) => {
                redux.newEntity(clientTableCfg.clientTable, "clientTable");
            });
        }

        if (nextProps.currentOrganization && !this.props.currentOrganization) {
            this.mountHotGlue(nextProps.currentOrganization);
        }
    }

    /*
    This function check whether the Chargebee session is valid or not Every configured minutes.
    by default, it is 2 mins. but Configurable in sys config.
     */
    async refreshChargebeeToken() {
        function clearTimer(chargebeeSessionTimer) {
            if (chargebeeSessionTimer) {
                clearTimeout(chargebeeSessionTimer);
            }
        }

        if (ChargebeeUtils.isChargebeeUser()) {
            let {
                isValid,
                nextRefresh
            } = await ChargebeeUtils.validateChargebeeUiSession();
            if (isValid) {
                clearTimer(this.state.chargebeeSessionTimer);
                let chargebeeSessionTimer = setTimeout(
                    this.refreshChargebeeToken,
                    nextRefresh
                );
                this.setState({
                    chargebeeSessionTimer: chargebeeSessionTimer
                });
            } else {
                clearTimer(this.state.chargebeeSessionTimer);
                User.signOut(true);
            }
        }
    }

    componentDidMount() {
        singleton({ cookies: this.props.cookies });
        this.refreshChargebeeToken();
    }

    init = (props, state) => {
        if (document.body) {
            document.body.dir = props.themeConfig.direction;
        }

        const {
            user,
            themeConfig,
            currentOrganization,
            userOrganizations,
            cookies
        } = props;

        const isDemoUser = (user) => {
            return (
                user &&
                user.attributes &&
                user.attributes.email === "livedemo@rev-lock.com"
            );
        };

        if (!user) {
            User.signIn();
        }

        if (user && !user.attributes) {
            return Auth.signOut({ global: true });
        }

        if (themeConfig !== this.props.themeConfig || !state.materialTheme) {
            state.materialTheme = createMuiTheme(themeConfig);
        }

        if (user && currentOrganization) {
            const simulatedLocalRedirectProfile = cookies.get(
                "simulatedLocalRedirectProfile"
            );

            if (simulatedLocalRedirectProfile)
                console.log(
                    `simulatedLocalRedirectProfile: ${simulatedLocalRedirectProfile} `
                );

            const currentServiceProfile =
                window.location.hostname === "localhost"
                    ? simulatedLocalRedirectProfile || config.serviceProfile
                    : config.serviceProfile;

            if (
                currentOrganization.profile &&
                currentServiceProfile !== currentOrganization.profile
            ) {
                const currentPath = window.location.pathname;
                const currentUrlProfile = currentPath.substring(0, 3);

                let redirectLocation =
                    currentUrlProfile === "/a/" ||
                    currentUrlProfile === "/b/" ||
                    currentUrlProfile === "/c/"
                        ? `/${currentPath.substring(3)}`
                        : currentPath;

                if (window.location.search) {
                    // cre is queryString was meant for Amplify Auth module. It's already consumed.
                    // We get rid of it here.
                    let { cre, ...rest } = queryString.parse(
                        window.location.search
                    );

                    if (Object.keys(rest).length) {
                        let qp = queryString.stringify(rest);
                        qp = !qp.startsWith("?") ? `?${qp}` : qp;
                        redirectLocation += qp;
                    }
                }

                console.log(
                    `org service profile ${currentOrganization.profile} is different from app service profile ${currentServiceProfile}, redirect location ${redirectLocation}`
                );

                const upgradeRedirect = cookies.get("upgradeRedirect");

                if (!upgradeRedirect) {
                    console.log(
                        `Set cookie upgradeRedirect to ${redirectLocation}`
                    );

                    cookies.set("upgradeRedirect", redirectLocation, {
                        path: "/",
                        expires: new Date(Date.now() + 1000 * 50)
                    });
                }

                if (window.location.hostname !== "localhost") {
                    // Establish the path where we will redirect to.
                    const redirectPath = `/${currentOrganization.profile}/index.html`;
                    console.log(
                        `Running in Cloud Deployment. window.location.href = ${redirectPath}`
                    );
                    window.location.href = redirectPath;
                } else {
                    cookies.set(
                        "simulatedLocalRedirectProfile",
                        currentOrganization.profile,
                        {
                            path: "/",
                            expires: new Date(Date.now() + 1000 * 60 * 30)
                        }
                    );

                    const redirectPath = `/index.html`;
                    console.log(
                        `Running in Local deployment. window.location.href = ${redirectPath}`
                    );
                    window.location.href = redirectPath;
                }
            } else {
                const currentPath = window.location.pathname;

                let redirectLocation = undefined;
                if (window.location.search) {
                    // cre is queryString was meant for Amplify Auth module. It's already consumed.
                    // We get rid of it here.
                    let { clientId, redirectUrl } = queryString.parse(
                        window.location.search
                    );

                    redirectLocation = redirectUrl;

                    if (clientId !== currentOrganization.id) {
                        const org = userOrganizations.find(
                            (org) => org.id === clientId
                        );

                        if (org) {
                            UserActions.setCurrentOrganization(org);
                            this.props.history.push(redirectUrl);
                            UserActions.invalidateOrganization();

                            return state;
                        }
                    }
                }

                if (redirectLocation == undefined) {
                    redirectLocation = cookies.get("upgradeRedirect");
                }

                // This is the path where profile is redirected to.
                let redirectPath = `/${config.serviceProfile}/index.html`;

                if (window.location.hostname === "localhost") {
                    redirectPath = `/index.html`;
                }

                console.log(
                    `Now checking if need to forward to a different route ${JSON.stringify(
                        {
                            currentPath,
                            redirectLocation,
                            redirectPath
                        }
                    )}`
                );

                //only accept a path redirect on explicit path
                if (
                    redirectLocation &&
                    (currentPath === redirectPath || currentPath === `/`)
                ) {
                    console.log(`Looks like we need to forward!!`);

                    cookies.remove("upgradeRedirect", {
                        path: "/",
                        expires: Date.now()
                    });

                    if (redirectLocation !== "/") {
                        console.log("Redirecting to ", redirectLocation);
                        this.props.history.push(redirectLocation);
                    }
                }
            }

            if (
                !isDemoUser(user) &&
                !(user && user.impersonated) &&
                (props.user !== this.props.user ||
                    props.currentOrganization !==
                        this.props.currentOrganization)
            ) {
                GA.setField({ dimension1: currentOrganization.id }); //organizationId
                GA.setField({ dimension2: user.id });
                GA.setField({ dimension3: currentOrganization.merchantId });
                GA.setField({ dimension4: user.attributes.email });
            }

            //ensure demo user remains anonymous or whatever user data this user has.
            if (isDemoUser(user) && !this.state.demoLogin) {
                this.setState({ demoLogin: true }, () => {
                    GA.setEvent({
                        category: "User",
                        action: "Live Demo Account Login",
                        value: 20
                    });
                });
            }
            //weak method to ensure that we only log this once per this users session..
        }

        //auto-select user organization
        if (
            !currentOrganization &&
            userOrganizations &&
            userOrganizations.length > 0
        ) {
            const orgId = cookies.get("orgId");
            let org;

            if (orgId) {
                // orgId previously selected is found
                org = userOrganizations.find((O) => O.id === orgId);
            }

            if (!org) {
                // By default select first test org.
                org = userOrganizations.find((org) =>
                    AccountingUtils.isTestId(org.id)
                );
            }

            if (!org) {
                // First org found.
                org = userOrganizations[0];
            }

            UserActions.setCurrentOrganization(org);
        }

        return state;
    };

    render() {
        const childProps = {};
        const { currentOrganization, user } = this.props;
        const materialTheme = this.state && this.state.materialTheme;
        const showCreateOrg = user && user.authorizations?.length === 0;
        const revEssential =
            (currentOrganization &&
                currentOrganization.license &&
                currentOrganization.license.name === "rev-essential") ||
            false;
        const superAdminLevel = SecurityUtils.getSuperAdminLevel(user);
        const disableActionsForQuickView =
            revEssential && superAdminLevel < SecurityUtils.SUPER_ADMIN_LEVEL_1;
        if (!materialTheme || !user) return <LinearProgress />;

        return (
            <CookiesProvider>
                <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    libInstance={moment}
                >
                    <>
                        <MuiThemeProvider theme={materialTheme}>
                            <IdleTimer
                                ref={(ref) => {
                                    this.idleTimer = ref;
                                }}
                                timeout={TIMEOUT}
                                onIdle={this.handleOnIdle}
                                debounce={250}
                            />
                            {!showCreateOrg && (
                                <Routes
                                    childProps={childProps}
                                    currentOrganization={currentOrganization}
                                    history={this.props.history}
                                    disableActionsForQuickView={
                                        disableActionsForQuickView
                                    }
                                />
                            )}
                            {showCreateOrg && (
                                <Org user={user} open={showCreateOrg} />
                            )}
                        </MuiThemeProvider>
                    </>
                </MuiPickersUtilsProvider>
            </CookiesProvider>
        );
    }
}

const createAccount = async (self) => {
    try {
        const {
            username,
            password,
            given_name,
            family_name,
            phone_number,
            website
        } = self.inputs;
        const { company } = self.state;

        self.setState({
            loading: true
        });

        const clientId = await API.getCompanyId({ website });

        const org = {
            id: clientId,
            name: company,
            merchantId: website,
            licenses: [
                {
                    type: "plan",
                    trial: true,
                    name: "rev-start",
                    label: "Rev Start",
                    addedOn: new Date().toISOString()
                }
            ],
            contacts: [
                {
                    name: `${given_name} ${family_name}`,
                    email: username,
                    phone: phone_number
                }
            ]
        };

        await Auth.signUp({
            username,
            password,
            attributes: {
                email: username,
                given_name,
                family_name,
                phone_number
            }
        });

        const params = {
            org,
            user: {
                firstName: given_name,
                lastName: family_name,
                email: username
            }
        };

        await API.signUpClient(params);
        //create the organization and user.

        self.changeState("confirmSignUp", username);

        self.setState({
            loading: false
        });
    } catch (err) {
        if (err.errorMessage) {
            err.message = err.errorMessage;
        }

        self.errorHandler(err);
        return;
    }
};

const canSignup = async (cookies) => {
    if (cookies) {
        const alreadyCalledSIgnup = cookies.get("alreadyCalledSignup");
        console.log(
            `checking cookies already called setup => ${alreadyCalledSIgnup}`
        );

        if (!alreadyCalledSIgnup) {
            const now = new Date();
            const expires = new Date(now.setTime(now.getTime() + 1000 * 60));
            cookies.set("alreadyCalledSignup", true, {
                path: "/",
                expires
            });
            try {
                const resp = await API.getMode();

                cookies.set("appModeResponse", resp, {
                    path: "/"
                });
            } catch (err) {
                console.log(err);
            }
        }

        const resp = cookies.get("appModeResponse");

        return resp && resp.mode !== "r";
    }
    return false;
};

function mapStateToProps(state) {
    let toReturn = {
        sync: sync(state),
        appConfig: getAppConfig(state),
        themeConfig: revlockTheme,
        user: currentUser(state),
        currentOrganization: currentOrganization(state),
        userOrganizations: userOrganizations(state),
        orgConfig: getOrgConfigurations(state),
        clientTables: getClientTablesConfig(state),
        customers: customers(state),
        products: products(state)
    };
    return toReturn;
}

const AppWithAuth = withAuthenticator(App, false, [
    <SignIn
        config={config}
        externalTheme={revlockTheme}
        otherComponent={MstrLogout}
        canSignup={canSignup}
    />,
    <ConfirmSignIn externalTheme={revlockTheme} />,
    <VerifyContact externalTheme={revlockTheme} />,
    <SignUp
        externalTheme={revlockTheme}
        config={config}
        onCreateAccount={createAccount}
    />,
    <ConfirmSignUp externalTheme={revlockTheme} />,
    <ForgotPassword externalTheme={revlockTheme} />,
    <RequireNewPassword externalTheme={revlockTheme} />
]);

export default compose(
    withRouter,
    withCookies,
    connect(mapStateToProps, {
        setData,
        toggleSidenav
    })
)(AppWithAuth);
