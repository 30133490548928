import React from "react";

//  import config from 'cfg/config';
import queryString from "query-string";
import appConfig from "cfg/config";
import * as API from "rest/API";

import { getCookie, setCookie } from "rest/API";

import { setData2 } from "redux/actions/data.actions";
import { hasRole } from "utils/auth";
import { addAuditEvent, getHotglueSecureToken } from "rest/API";
import { Storage } from "aws-amplify";

import swal from "@sweetalert/with-react";
import "./alert.css";

import ErrorDetailsBox from "../components/ErrorDetailsBox";
import { AccountingUtils } from "revlock-accounting";

const { config } = require("../../package.json");

let singletonInstances = {};

export function componentToHex(c) {
    const hex = Math.round(c).toString(16);

    return hex.length == 1 ? "0" + hex : hex;
}

export function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function colorToHex(color) {
    // This color is from the distinct-colors api.
    const r = color._rgb[0];
    const g = color._rgb[1];
    const b = color._rgb[2];

    return rgbToHex(r, g, b);
}

export const compose = (...funcs) =>
    funcs.reduce(
        (a, b) => (...args) => a(b(...args)),
        (arg) => arg
    );

export function singleton(instances) {
    Object.keys(instances).forEach(
        (key) => (singletonInstances[key] = instances[key])
    );
}

export function get(key) {
    return singletonInstances[key];
}

export function backend() {
    return config.backend;
}

export function bind(instance, ...functions) {
    functions.forEach(
        (func) => (instance[func] = instance[func].bind(instance))
    );
}

// Returns if a value is an array
export function isArray(value) {
    return Array.isArray(value);
}

// Returns if a value is a function
export function isFunction(value) {
    return typeof value === "function";
}

// Returns if a value is an object
export function isObject(value) {
    return value && typeof value === "object";
}

// Returns if a value is really a number
export function isNumber(value) {
    return typeof value === "number" && isFinite(value);
}

// Returns if a value is a string
export function isString(value) {
    return typeof value === "string" || value instanceof String;
}

// convert HTML to text.
export function stripHtml(html) {
    if (!html) return "";
    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}

export async function getHGPublicApiKey(orgId, hgConfig) {
    if (window.HotGlue) {
        try {
            const response = await getHotglueSecureToken(orgId);
            hgConfig.api_key = response.publicApiKey;

            window.HotGlue.mount(hgConfig);
        } catch (e) {
            console.error(e);
        }
    }
}

export async function showHGDialog({
    orgId,
    flows = ["Commissions", "Invoices", "Sales"],
    callback,
    callbackType = "source",
    linkedCallback,
    unlinkedCallback,
    isSubTenantEnabled = false
}) {
    const tenant_id = orgId;
    if (window.HotGlue && window.HotGlue.show) {
        const params = {
            multipleSources: true,
            subTenantsEnabled: isSubTenantEnabled,
            showSubTenantsId: isSubTenantEnabled,
            filter: {
                flowFilter: (flow) => flows.includes(flow.name)
            },
            listener: {
                onSourceLinked: (source, flow_id) => {
                    if (callbackType == "source" && linkedCallback) {
                        linkedCallback(source, flow_id);
                    }
                },
                onSourceUnlinked: (source, flow_id) => {
                    if (callbackType == "source" && unlinkedCallback) {
                        unlinkedCallback(source, flow_id);
                    }
                },
                onTargetLinked: (target, flow_id) => {
                    if (callbackType == "target" && linkedCallback) {
                        linkedCallback(target, flow_id);
                    }
                },
                onTargetUnlinked: (target, flow_id) => {
                    if (callbackType == "target" && unlinkedCallback) {
                        unlinkedCallback(target, flow_id);
                    }
                },
                onStartJob: (tap, flow, userId) => {
                    callback();
                    addAuditEvent(orgId, {
                        eventType: "data_sync_start",
                        entityType: "flow",
                        entityId: flow,
                        message: `User started HotGlue job ${
                            tap ? "with tap id " + tap : ""
                        }`
                    });
                },
                onSourceLinkCanceled: (tap, flow_id) => {
                    addAuditEvent(orgId, {
                        eventType: "data_sync_cancelled",
                        entityType: "flow",
                        entityId: flow_id,
                        message: `HotGlue job cancelled ${
                            tap ? "with tap id" + tap : ""
                        }`
                    });
                }
            }
        };

        if (hasRole("admin")) {
            try {
                const response = await getHotglueSecureToken(orgId);
                const secureJwtToken = response.token;
                if (secureJwtToken) params.jwtToken = secureJwtToken;
            } catch (e) {
                console.log(e);
            }
        }

        window.HotGlue.show(tenant_id, params);
    } else
        swal({
            text: `Our sync back-end is currently unavailable! Try again later.`,
            title: "Error",
            icon: "warning",
            buttons: false,
            timer: 3000
        });
}

export function showImportDialog() {
    setData2("showImportDialog", true);
}

export function showAlert(props) {
    const { details = undefined } = props;
    const swalProps = { ...props };

    if (details && details !== null) {
        swalProps.content = <ErrorDetailsBox details={details} />;
        delete swalProps.details;
    }

    return swal(swalProps);
}

export function showError(exc) {
    const { details = undefined } = exc;

    const swalProps = exc;
    swalProps.icon = swalProps.icon || "error";
    swalProps.title = swalProps.title || "Something is not right";
    swalProps.buttons = swalProps.buttons || true;
    swalProps.text = typeof swalProps.text === "function" ? "" : swalProps.text;

    if (details && details !== null) {
        swalProps.content = <ErrorDetailsBox details={details} />;
        delete swalProps.details;
    }

    return swal(swalProps);
}

// Returns the mstrGroupName
export function getMicroStrategyGroupName(clientId, clientName) {
    const orgName = AccountingUtils.isTestId(clientId)
        ? clientName + "_Test"
        : clientName;

    return orgName + "_" + clientId;
}

export function downloadFromS3(s3Key, name) {
    if (!name) {
        name = s3Key.replace(/^.*[\\\/]/, "");
    }

    const downloadURI = (uri, name) => {
        var link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    Storage.get(s3Key, {
        level: "public"
    })
        .then((result) => {
            downloadURI(result, name);
        })
        .catch((err) => {
            console.log(err);
        });
}

const toRow = (record, columns) => {
    let toReturn =
        (record &&
            columns.map((column) => {
                return record[column];
            })) ||
        [];
    return toReturn;
};

const comparators = {
    period: (a, b) => (a == b ? 0 : a < b ? -1 : 1),
    number: (a, b) => (a == b ? 0 : a < b ? -1 : 1),
    string: (a, b) => (a == b ? 0 : a < b ? -1 : 1)
};

const comparator = (columns, sortCols, sortTypes) => {
    let sortColIndexes = sortCols.map((sortCol) => columns.indexOf(sortCol));
    return function(a, b) {
        let toReturn = sortColIndexes.reduce((result, softColIndex, i) => {
            if (result != 0) return result;
            return comparators[sortTypes[i]](a[softColIndex], b[softColIndex]);
        }, 0);
        return toReturn;
    };
};

export function exportFormatData(exportMetadata, header = true) {
    let toReturn = {};

    Object.keys(exportMetadata).forEach((sheet) => (toReturn[sheet] = []));

    Object.keys(exportMetadata).forEach((sheet) => {
        let { columns, sortCols, sortTypes, get } = exportMetadata[sheet];
        let exportData = [];
        let _exportData = get(columns);
        exportData = exportData.concat(
            _exportData.map((record) => toRow(record, columns))
        );

        exportData.sort(comparator(columns, sortCols, sortTypes));
        if (header) exportData.unshift(columns);
        toReturn[sheet] = toReturn[sheet].concat(exportData);
    });

    return toReturn;
}

export const str2id = (str) => str.replace(/\W/g, "_");

export const ensureOrgProfile = async (orgId, context = {}, callback) => {
    let currentServiceProfile = appConfig.serviceProfile;

    let { url, orgProfile } = context;

    // Only runs when running locally
    if (window.location.hostname === "localhost") {
        const simulatedLocalRedirectProfile = getCookie(
            "simulatedLocalRedirectProfile"
        );
        if (simulatedLocalRedirectProfile)
            console.log(
                `simulatedLocalRedirectProfile: ${simulatedLocalRedirectProfile} `
            );
        currentServiceProfile =
            simulatedLocalRedirectProfile || appConfig.serviceProfile;
    }

    if (!orgProfile) {
        orgProfile = await API.getOrgProfile(orgId);
    }

    // It's unknown when backend dont have the org in version lookup index.
    // Should never happen, but I saw some test orgs in production that has this issue.
    if (orgProfile === "unknown" || currentServiceProfile === orgProfile) {
        callback && callback();
        return;
    }

    let currentPath = window.location.pathname;
    let _queryString = window.location.search;

    if (url) {
        const _url = new URL(url);
        _queryString = _url.search;
        currentPath = _url.pathname;
    }

    const currentUrlProfile = currentPath.substring(0, 3);

    let redirectLocation =
        currentUrlProfile === "/a/" ||
        currentUrlProfile === "/b/" ||
        currentUrlProfile === "/c/"
            ? `/${currentPath.substring(3)}`
            : currentPath;

    if (_queryString) {
        // cre is queryString was meant for Amplify Auth module. It's already consumed.
        // We get rid of it here.
        let { cre, ...rest } = queryString.parse(_queryString);

        if (Object.keys(rest).length) {
            let qp = queryString.stringify(rest);
            qp = !qp.startsWith("?") ? `?${qp}` : qp;
            redirectLocation += qp;
        }
    }

    console.log(
        `org service profile ${orgProfile} is different from app service profile ${currentServiceProfile}, redirect location ${redirectLocation}`
    );

    const upgradeRedirect = getCookie("upgradeRedirect");

    if (!upgradeRedirect) {
        console.log(`Set cookie upgradeRedirect to ${redirectLocation}`);
        setCookie("upgradeRedirect", redirectLocation, "/", 1);
    }

    setCookie("orgId", `${orgId}`, "/");

    if (window.location.hostname !== "localhost") {
        // Establish the path where we will redirect to.
        const redirectPath = `/${orgProfile}/index.html`;
        console.log(
            `Running in Cloud Deployment. window.location.href = ${redirectPath}`
        );
        window.location.href = redirectPath;
    } else {
        setCookie("simulatedLocalRedirectProfile", orgProfile, "/", 30);

        const redirectPath = `/index.html`;
        console.log(
            `Running in Local deployment. window.location.href = ${redirectPath}`
        );
        window.location.href = redirectPath;
    }
};
