import React from "react";
import { useHistory } from "react-router-dom";
import useWebSocket from "react-use-websocket";
import config from "cfg/config";

const { wsBaseUrl } = config;
import { moment } from "revlock-webutils";
import {
    Box,
    Button,
    IconButton,
    LinearProgress,
    Paper,
    Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import {
    RevenueSummaryActions,
    UserActions,
    CommissionSummaryActions,
    ProductActions,
    setData2,
    SalesOrderActions
} from "redux/actions";
import { putOrgConfigurations, getOrgConfigurations } from "rest/API";
import { showAlert } from "utils/WebUtils";
import { currentOrganization } from "redux/selectors";

/**
 * Handlers that handle when we get job progress notifications
 *
 * completeAction: what happens when the job has completed successfully
 * action: what you want to happen when you click the button at the end of job completion
 * message: what it shows on the button
 * failureAction: this is a function that gets called if the job failed
 * failureMessage: the message on the failed button that the user would click on
 */
const jobHandlers = {
    warehouseJob: {
        action: (history, currentOrganization) =>
            history.push(
                `/${currentOrganization.id}/mstr-native-report-parent`
            ),
        completeAction: (history, currentOrganization) => {
            RevenueSummaryActions.invalidate();
            CommissionSummaryActions.invalidate();
            SalesOrderActions.invalidate();
        },
        message: "View Reports",
        completeMessage: "Warehouse Re-Published"
    },
    clearTenantJob: {
        completeAction: (history, currentOrganization) => {
            UserActions.invalidateOrganization();
        },
        completeMessage: "Environment Cleared"
    },
    sync: {
        action: (history, currentOrganization) =>
            history.push(`/${currentOrganization.id}/pages/sales`),
        message: "View orders",
        completeAction: (history, currentOrganization) => {
            RevenueSummaryActions.invalidate();
            CommissionSummaryActions.invalidate();
            SalesOrderActions.invalidate();
        },
        completeMessage: "Sync Job Completed"
    },
    sspAnalyzerJob: {
        failureAction: (history, currentOrganization) => {
            increaseLookbackPeriod(currentOrganization);
        },
        failureMessage: `Increase look back period`,
        action: (history, currentOrganization) => {
            history.push(`/${currentOrganization.id}/ssp/analyzer?reload=true`);
        },
        completeAction: (history, currentOrganization) => {
            setData2("sspAnalyzerRefresh", "plan");
        },
        message: "See analysis",
        completeMessage: "Analysis Completed"
    },
    reopenPeriods: {
        completeAction: (history, currentOrganization) => {
            RevenueSummaryActions.invalidate();
            CommissionSummaryActions.invalidate();
            SalesOrderActions.invalidate();
        },
        completeMessage: "Period Re-opened"
    },
    accountingCloseJob: {
        completeAction: (history, currentOrganization) => {
            UserActions.invalidateOrganization();
        },
        completeMessage: "Accounting Period Closed"
    },
    postingJob: {
        action: (history, currentOrganization) => {
            const isRestatementMode =
                (currentOrganization &&
                    currentOrganization.isRestatementMode) ||
                false;

            const reportId = isRestatementMode
                ? "FF82B4CA4EAE98A8D55E07815A746866"
                : "38018909406C6EDC728E21999DC98EBF";
            setData2("pageTitle", "Journal Entry Report");
            history.push(
                `/${currentOrganization.id}/mstr-native-report/native/${reportId}`
            );
        },
        message: "View Journals",
        completeMessage: "Journals Created"
    },
    delayedRevenueJob: {
        action: (history, currentOrganization) => {
            history.push(`/${currentOrganization.id}/pages/customers`);
        },
        completeAction: (history, currentOrganization) => {
            RevenueSummaryActions.invalidate();
            CommissionSummaryActions.invalidate();
            SalesOrderActions.invalidate();
        },
        message: "View Customers",
        completeMessage: "Pending Collectibillity Updated"
    },
    reclassJob: {
        action: (history, currentOrganization) => {
            const isRestatementMode =
                (currentOrganization &&
                    currentOrganization.isRestatementMode) ||
                false;

            const reportId = isRestatementMode
                ? "FF82B4CA4EAE98A8D55E07815A746866"
                : "38018909406C6EDC728E21999DC98EBF";
            setData2("pageTitle", "Journal Entry Report");
            history.push(
                `/${currentOrganization.id}/mstr-native-report/native/${reportId}`
            );
        },
        completeMessage: "Journal Reclass Completed",
        message: "View Journals"
    },
    manualAdjustmentJob: {
        action: (history, currentOrganization) => {
            history.push(`/${currentOrganization.id}/adjustments`);
        },
        completeAction: (history, currentOrganization) => {
            RevenueSummaryActions.invalidate();
        },
        message: "View Adjustments",
        completeMessage: "Manual Adjustment Job Completed"
    },
    reprocessSalesOrder: {
        action: (history, currentOrganization) =>
            history.push(`/${currentOrganization.id}/pages/sales`),
        completeAction: (history, currentOrganization) => {
            RevenueSummaryActions.invalidate();
            CommissionSummaryActions.invalidate();
        },
        message: "View Orders",
        completeMessage: "Sales Order Successfully Re-Processed"
    },
    journalPostingJob: {
        action: (history, currentOrganization) => {
            const isRestatementMode =
                (currentOrganization &&
                    currentOrganization.isRestatementMode) ||
                false;

            const reportId = isRestatementMode
                ? "FF82B4CA4EAE98A8D55E07815A746866"
                : "38018909406C6EDC728E21999DC98EBF";
            setData2("pageTitle", "Journal Entry Report");
            history.push(
                `/${currentOrganization.id}/mstr-native-report/native/${reportId}`
            );
        },
        completeMessage: "Journals Posted",
        message: "View Journals"
    },
    forexUpdateJob: {
        action: (history, currentOrganization) => {
            setData2("pageTitle", "Forex Exchange Rates");
            history.push(
                `/${currentOrganization.id}/mstr-native-report-parent`
            );
        },
        completeMessage: "Forex Updated",
        message: "View Report"
    },
    deleteProductsJob: {
        action: (history, currentOrganization) => {
            setData2("pageTitle", "Products");
            history.push(`/${currentOrganization.id}/products`);
        },
        completeAction: (history, currentOrganization) => {
            ProductActions.invalidate();
        },
        completeMessage: "Deleted Products Successfully",
        message: "View Products"
    },
    acPeriodCloseJob: {
        action: (history, currentOrganization) => {
            history.push(`/${currentOrganization.id}/accountingcentral`);
        },
        completeAction: (history, currentOrganization) => {
            UserActions.invalidateOrganization();
        },
        completeMessage: "Accounting Central Period Closed"
    },
    exportJob: {
        action: (history, currentOrganization) => {
            setData2("pageTitle", "User Exports");
            history.push(
                `/${currentOrganization.id}/mstr-native-report/bucket/export-data`
            );
        },
        completeMessage: "Your file is exported",
        message: "View File(s)"
    }
};

const increaseLookbackPeriod = async (currentOrganization) => {
    try {
        const orgConfig = await getOrgConfigurations(currentOrganization.id);
        const config = orgConfig.find(
            (cfg) => cfg.id === "properties/sspAnalyzer/lookback-period"
        );
        let sspAnalyzerlookbackPeriod = (config && config.value) || 6;
        console.log(sspAnalyzerlookbackPeriod);
        sspAnalyzerlookbackPeriod += 6;
        const newOrgConfig = {
            id: "properties/sspAnalyzer/lookback-period",
            value: sspAnalyzerlookbackPeriod
        };
        await putOrgConfigurations(currentOrganization.id, {
            configs: [newOrgConfig]
        });
        showAlert({
            title: "Lookback period increased Successfully.",
            text: "Please run SSP Analysis.",
            buttons: "OK"
        });
    } catch (err) {
        console.error(err);
        showAlert({
            title: "Something went wrong.",
            text: err.errorMessage || err.text || "Something went wrong.",
            buttons: "OK"
        });
    }
};

/**
 * General purpose handlers that handle general notifications
 */
const generalHandlers = {
    sspAnalyzerConfigError: {
        actionMessage: `Reset Range`,
        actionHandler: (history, currentOrganization) => alert("Hi there")
    }
};

const useStyles = makeStyles((theme) => ({
    rootPaper: {
        margin: 1,
        paddingBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
        backgroundColor: "#F6F4F4",
        textWrap: "wrap"
    },
    jobHeading: {
        fontWeight: 700
    },
    actionButton: {
        marginTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    }
}));

const useProgressStyles = makeStyles((theme) => ({
    progressRoot: {
        marginTop: theme.spacing(0.5),
        height: 10,
        borderRadius: 5
    },
    progressColorPrimary: {
        backgroundColor: "green"
    },
    progressBar: {
        borderRadius: 5
    }
}));

function ProgressBar({ estimatedEnd, estimatedDuration, state }) {
    console.log("==>", state);

    const classes = useProgressStyles();
    const [refresh, setRefresh] = React.useState(Date.now());
    const [intervalId, setIntervalId] = React.useState(0);

    React.useEffect(() => {
        const scheduleId = setInterval(() => {
            setRefresh(Date.now());
        }, 10000);
        setIntervalId(scheduleId);
    }, []);

    React.useEffect(() => {
        return () => {
            intervalId && clearInterval(intervalId);
        };
    }, []);

    React.useEffect(() => {
        if (state === "Completed" || state === "Failed") {
            intervalId && clearInterval(intervalId);
        }
    }, [state]);
    //clean up polling

    const { timeRemaining, progress } = React.useMemo(() => {
        if (
            state === "Completed" ||
            state === "Failed" ||
            !estimatedEnd ||
            !estimatedDuration
        ) {
            return {
                timeRemaining: 0.0,
                progress: 100.0
            };
        }

        const timeRemaining =
            estimatedEnd && estimatedEnd > Date.now()
                ? estimatedEnd - Date.now()
                : 60 * 1000;
        const progress = (1.0 - timeRemaining / estimatedDuration) * 100;

        return {
            timeRemaining,
            progress
        };
    }, [refresh, state]);

    console.log(`PROGRESS: Refresh is now.. ${refresh} and ${timeRemaining}`);

    const progressClass = {
        root: classes.progressRoot,
        bar: classes.progressBar
    };

    let message = `ETA ${moment.duration(timeRemaining).humanize(true)}`;
    if (state === "Completed") {
        progressClass.colorPrimary = classes.progressColorPrimary;
        message = "Completed";
    }

    return (
        <Box display={"flex"} margin={1} flexDirection={"column"}>
            <small>{`${message}`}</small>
            <LinearProgress
                classes={progressClass}
                variant="determinate"
                value={progress}
            />
        </Box>
    );
}

export default function JobProgress(props) {
    const classes = useStyles();
    const history = useHistory();

    const { currentOrganization, currentUser } = props;
    const userId = currentUser?.attributes?.email;

    const [closedAt, setClosedAt] = React.useState(0);

    if (!userId || !currentOrganization) {
        return null;
    }
    //nothing to show if not logged in yet
    const didUnmount = React.useRef(false);
    const wsUrl = `${wsBaseUrl}?clientId=${currentOrganization.id}&userId=${userId}`;
    const d = useWebSocket(wsUrl, {
        shouldReconnect: (closeEvent) => {
            return didUnmount.current === false;
        },
        reconnectAttempts: 10,
        reconnectInterval: 15000,
        retryOnError: true
    });
    //load recent processes

    const { lastJsonMessage } = d;

    React.useEffect(() => {
        return () => {
            didUnmount.current = true;
        };
    }, []);

    React.useEffect(() => {
        if (lastJsonMessage) {
            const { state, jobType } = lastJsonMessage;
            const handler = jobHandlers[jobType];
            if (handler && handler.completeAction) {
                if (state === "Completed") {
                    handler.completeAction(history, currentOrganization);
                }
            }
        }
    }, [lastJsonMessage]);

    /**
     * If this is a message about a job then it will have these extra attributes
     * we use information to generate the progress notification etc.
     *
     * @param state
     * @param estimatedEnd
     * @param estimatedDuration
     * @param jobType
     * @returns {JSX.Element}
     */
    function showJobProgress({
        state,
        estimatedEnd,
        estimatedDuration,
        jobType,
        cancelReason
    }) {
        const timeRemaining =
            estimatedEnd && estimatedEnd > Date.now()
                ? estimatedEnd - Date.now()
                : 60 * 1000;

        const progress = (1.0 - timeRemaining / estimatedDuration) * 100;
        const handler = jobHandlers[jobType];

        return (
            <Box
                display={"flex"}
                margin={1}
                flexDirection={"column"}
                id="jobProgressBox"
            >
                <small>
                    {state === "Queued" && "Queued"}
                    {state === "Failed" &&
                        `Job failed. Something unexpected happened, we are currently investigating. Please try again later.`}
                </small>
                {(state === "Running" || state === "Completed") && (
                    <ProgressBar
                        state={state}
                        estimatedDuration={estimatedDuration}
                        estimatedEnd={estimatedEnd}
                    />
                )}
                {state === "Completed" && handler?.message && (
                    <div className={classes.actionButton}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() =>
                                handler.action(history, currentOrganization)
                            }
                        >
                            {handler.message}
                        </Button>
                    </div>
                )}
                {state == "Failed" && handler?.failureMessage && (
                    <div className={classes.actionButton}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() =>
                                handler.failureAction(
                                    history,
                                    currentOrganization
                                )
                            }
                        >
                            {handler.failureMessage}
                        </Button>
                    </div>
                )}
            </Box>
        );
    }

    /**
     * Handler general other notifications that can show up on the notification bar
     *
     * @param messageDetail
     * @param actionHandler
     * @param actionMessage
     * @returns {JSX.Element}
     */
    function showGeneralMessage({ messageDetail, actionHandler }) {
        const handler = generalHandlers[actionHandler];

        return (
            <Box display={"flex"} margin={1} flexDirection={"column"}>
                <small>${messageDetail}</small>
                {handler && (
                    <div className={classes.actionButton}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() =>
                                handler.actionHandler(
                                    history,
                                    currentOrganization
                                )
                            }
                        >
                            {handler.message || "Completed"}
                        </Button>
                    </div>
                )}
            </Box>
        );
    }

    if (lastJsonMessage) {
        const { message, createdOn, type, jobType, state } = lastJsonMessage;

        if (createdOn < closedAt) {
            //ignore older updates
            return null;
        }

        const completeMessage =
            jobHandlers[jobType]?.completeMessage || message;

        return (
            <Paper className={classes.rootPaper}>
                <Box
                    display="flex"
                    margin={1}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Typography className={classes.jobHeading}>
                        {state == "Completed" ? completeMessage : message}
                    </Typography>
                    <Box
                        display="flex"
                        sx={{
                            my: 1,
                            mx: 0
                        }}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setClosedAt(Date.now());
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    </Box>
                </Box>
                {type === "job" && showJobProgress(lastJsonMessage)}
                {type !== "job" && showGeneralMessage(lastJsonMessage)}
            </Paper>
        );
    }

    return null;
}
